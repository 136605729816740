import styled from 'styled-components';
import { mediaQueries } from '_constants/cssVars';

export const TournamentCardContainer = styled.a<{
  hoverBgColor: string;
  bgColor: string;
}>`
  position: relative;

  height: 260px;
  width: 303px;

  box-shadow: 0 3px 34px 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;

  border-radius: 20px;
  :hover {
    cursor: pointer;
    [class*="StartButton"] {
      background: ${({ hoverBgColor }) => hoverBgColor};
    }
    [class*="StartText"] {
      text-decoration: underline;
    }
  }

  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;

  background: ${({ bgColor }) => bgColor};
  color: #ffffff;

  font: 'Rubik-Medium';
  [class*="StartArrow"] {
    display: none;
  }

  @media ${mediaQueries.tablet} {
    width: 385px;
    [class*="StartArrow"] {
      display: inline;
    }
  }
`;

export const IconContainer = styled.div`
  width: 60px;
  height: 60px;

  margin: 30px 30px 0 30px;

  border: solid 1px gray;
  border-radius: 50px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin: 0 30px;

  font-size: 23px;
  font-weight: 500;
`;

export const ButtonContainer = styled.div`
  width: 100%;

  height: 80px;
  border-radius:  0 0 20px 20px;

  > * {
    padding: 0 30px;
  }

  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  font-size: 18px;
`;

export const StartText = styled.span`
  :first-letter {
    text-transform: uppercase;
  }
`;
