import React from 'react';
import styled from 'styled-components';
import TournamentCard from 'components/Predictor/Components/TournamentCard';
import RootContainer from 'components/Predictor/Components/RootContainer';
import { mediaQueries } from '_constants/cssVars';
import { useConfig } from 'lib/useConfig';

const Version = styled.span`
  color: transparent;
`;

const TournamentsWrapper = styled.div`
  max-width: 800px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 20px;
  @media ${mediaQueries.tablet} {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: start;
  }
`;

const RootPage = () => {
  const config = useConfig();
  return (
    <RootContainer>
      <TournamentsWrapper>
        { config.games.filter(({ completed }) => !completed).map(({
          name, shortTitle, version, id, iconUrl, mustNotBeNamed,
        }) => (
          <TournamentCard
            id={id}
            key={name}
            name={name}
            title={mustNotBeNamed ? 'Tournament Predictor' : shortTitle}
            version={version}
            iconUrl={iconUrl}
          />
        ))}
      </TournamentsWrapper>
      <Version>{process.env.NEXT_PUBLIC_VERSION}</Version>
    </RootContainer>
  );
};

RootPage.propTypes = {};
RootPage.defaultProps = {};

export default RootPage;
