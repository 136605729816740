import React, { useMemo } from 'react';
import styled from 'styled-components';
import { widths, mediaQueries } from '_constants/cssVars';
import useUser from 'lib/useUser';
import { useTournamentConfig } from 'lib/useTournamentConfig';
import LogoutButton from '../Login/LogoutButton';
import FotmobLogo from '../SVGs/FotmobLogo';
import NoSSR from '../NoSSR';

const Header = styled.header<{ height: number }>`
  width: 100%;
  float: left;
  margin: 0;
  padding: 0;
  border-bottom: 0.5px solid #ececec;

  display: flex;
  justify-content: space-around;

  height: ${({ height }) => height || 64}px;
`;

const Container = styled.div<{ headerWidth?: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: start;
  justify-content: space-between;

  width: 100%;
  max-width: ${({ headerWidth }) => (headerWidth > 0
    ? `${headerWidth}px`
    : widths.predictorContentMaxWidth)};
  @media ${mediaQueries.mobile} {
    padding: 0 20px;
  }
`;

const LeftStuff = styled.div`
  display: flex;
  align-items: center;
`;

const RightStuff = styled.div`
  display: flex;

  flex-direction: row-reverse;
  align-items: center;
  align-content: center;
`;

const Title = styled.h1`
  font-size: 16px;
  font-weight: normal;
`;

const TournamentName = styled.span`
  font-weight: 500;
`;

const Logo = styled(FotmobLogo)`
  padding-right: 19px;
`;

export const PredictorHeader: React.FC<{
  height?: number;
  width?: number;
  theLogo?: React.ReactNode;
  title?: React.ReactNode;
  rightMost?: React.ReactNode;
}> = ({
  height, width, theLogo, title, rightMost,
}) => (
  <Header height={height}>
    <Container headerWidth={width}>
      <LeftStuff>
        { theLogo }
        { title }
      </LeftStuff>
      <RightStuff>
        { rightMost }
      </RightStuff>
    </Container>
  </Header>
);

const TournamentHeader: React.FC = () => {
  const { loggedIn } = useUser();
  const { title: tournamentTitle, mustNotBeNamed, name } = useTournamentConfig();
  const title = mustNotBeNamed
    ? 'Tournament'
    : tournamentTitle ?? name;

  const LogoMemo = useMemo(() => <Logo />, []);
  const TitleMemo = useMemo(() => (
    <Title>
      <TournamentName>{title}</TournamentName>
      {' Predictor'}
    </Title>
  ), [tournamentTitle, name]);
  const rightContentMemo = useMemo(() => loggedIn && (<NoSSR><LogoutButton /></NoSSR>), [loggedIn]);

  return (
    <PredictorHeader
      theLogo={LogoMemo}
      title={TitleMemo}
      rightMost={rightContentMemo}
    />
  );
};

export default TournamentHeader;
