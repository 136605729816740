import { useTranslation } from 'lib/i18n/useTranslation';
import Image from 'next/image';
import Link from 'next/link';
import React, { FC } from 'react';
import { useResolvePredictorTheme } from 'lib/usePredictorTheme';
import { RightArrow3Svg } from '../SVGs/RightArrow3';
import * as st from './tournamentCard.styles';

type TournamentCard = {
  id: string;
  name: string;
  title: string;
  version: string;
  iconUrl: string;
};

const TournamentCard: FC<TournamentCard> = ({
  id, name, title, version, iconUrl,
}) => {
  const { t } = useTranslation();
  const { cardColor, hover } = useResolvePredictorTheme(id);

  return (
    <Link href={`/${name}`} passHref>
      <st.TournamentCardContainer hoverBgColor={hover.cardColor} bgColor={cardColor}>
        <st.IconContainer>
          <Image src={iconUrl} width="30" height="30" alt="Tournament icon" />
        </st.IconContainer>
        <st.TitleContainer>
          <span>{title}</span>
          <span>{version}</span>
        </st.TitleContainer>
        <st.ButtonContainer className="StartButton">
          <st.StartText className="StartText">{t('startpage_start_now')}</st.StartText>
          <span className="StartArrow"><RightArrow3Svg /></span>
        </st.ButtonContainer>
      </st.TournamentCardContainer>
    </Link>
  );
};

export default TournamentCard;
