import React from 'react';
import Head from 'next/head';
import { getAllAlternateLangUrl } from 'helpers/getAlternateLangUrl';
import PredictorHead from 'components/Predictor/Components/Head';
import PredictorRootPage from 'components/Predictor/Pages/RootPage';
import { setupServerSideRedux } from 'state/getServerSideProps';
import { PredictorHeader } from 'components/Predictor/Components/TournamentHeader/TournamentHeader';
import FotMobLogo from 'components/Predictor/Components/FotMobLogo';
import Footer from 'components/Predictor/Pages/StartPage/Footer';
import styled from 'styled-components';
import { getTournaments } from 'state/remoteCalls/predictorRemoteCalls';
import { cacheResponseForOneDay } from 'lib/cache';

const pageTitle = 'FotMob Tournament Predictor';
const description = 'Predict the outcome and compete with your friends';

const Logo = styled(FotMobLogo)`
  padding-right: 13px;
  width: 120px;
  height: 20px;
`;

const Title = styled.span`
  font-size: 18px;
`;

const RootPage = () => (
  <>
    <PredictorHead title={pageTitle} description={description} imageHeight="1280" imageWidth="2260" />
    <Head>
      {getAllAlternateLangUrl()}
    </Head>
    <PredictorHeader height={80} width={800} theLogo={<Logo />} title={<Title>Predictors</Title>} />
    <PredictorRootPage />
    <Footer />
  </>
);

export const getServerSideProps = async (ctx) => {
  const {
    locales, query, res, req,
  } = ctx;

  const [missingLocale, game] = query.slug || [];
  const config = await getTournaments();
  const isValidGame = config.games.map((g) => g.name).includes(game);
  const isMissingLocale = !locales.includes(missingLocale);

  if (isValidGame && isMissingLocale) {
    const [, ...parts] = req.url
      .split('/')
      .filter(Boolean);

    res.setHeader('Location', `/${parts.join('/')}`);
    res.statusCode = 302;
    res.end();
  }

  const serverSideProps = await setupServerSideRedux(false, true)(ctx);

  cacheResponseForOneDay(res);
  return serverSideProps;
};

export default RootPage;
